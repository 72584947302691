.enter_wrapper {
  position: relative;
  margin-bottom: 30px;
  .heading_wrapper {
    padding-top: 10rem;
    position: relative;
    height: 365px;
  }
  h1 {
    color: #fff;
  }
  .filter_area {
    padding: 48px 0;
  }
}
@media (min-width: 1492px) {
  .enter_wrapper {
    margin-bottom: 60px;
  }
}

@media (max-width: 768px) {
  .enter_wrapper {
    .heading_wrapper {
      height: auto;
      padding-top: 0;
    }
    .filter_area {
      gap: 18px !important;
      padding: 18px 0;
      margin-top: 15px;
      .select_input {
        width: calc(50% - 18px);
      }
    }
  }
}
