.filter_area {
  padding: 48px 0;
  button {
    margin: 0;
  }
  .slider_wrapper {
    min-width: 300px;
    .select_like_input {
      cursor: pointer;
      user-select: none;
      border-bottom: 2px solid #d9d9d9;
      border-radius: 4px;
      padding: 8px 12px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      min-width: 200px; // Set the width as needed
      box-shadow: 0 2px 0 rgba(0, 0, 0, 0.015);
      transition: all 0.3s;
      &:hover {
        border-color: #40a9ff;
      }
    }


    .slider_container {
      // position: absolute; // Adjust if you want it to be relative to a parent div with position: relative;
      z-index: 10;
      width: 100%; // Adjust the width as necessary
      padding: 10px;
      background: white;
      border-radius: 4px;
      box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);

      .ant-slider {
        margin-top: 16px;
      }

      .ant-slider-rail,
      .ant-slider-track,
      .ant-slider-handle {
        height: 14px; // Larger handle for easier dragging
      }

      .ant-slider-handle {
        width: 14px;
        border: solid 2px #40a9ff; // Color of the handle border
        &:focus {
          box-shadow: none; // Removes default focus shadow
        }
      }

      .ant-slider-track {
        background-color: #40a9ff; // Color of the slider track
      }
    }
  }
}
@media (max-width: 768px) {
  .filter_area {
    gap: 18px !important;
    .select_input {
      width: calc(50% - 18px);
    }
  }
}
