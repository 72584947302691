.news_wrapper {
  position: relative;
  padding-top: 98px;
  margin-top: 50px;
  margin-bottom: 48px;
  h2 {
    margin-bottom: 72px;
    color: var(--text-white);
  }
}

@media (max-width: 768px) {
  .news_wrapper {
    padding-top: 54px;
    h2 {
      margin-bottom: 52px;
    }
  }
}
