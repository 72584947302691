:global(.accessibility-mode) .thing_item {
  border: 4px solid black !important;
  padding: 20px !important;
  background-color: #fff !important;

  .festivalContent {
    padding-left: 0px !important;
    margin-left: -60px !important;
  }

}

.things_wrapper {
  margin-top: 69px;
  padding-top: 83px;
  position: relative;
  z-index: 4;

  .event_all {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 30px;

    a {
      display: inline-flex;
      color: var(--text-gray);
      text-decoration: none;
    }
  }

  .thing_item {
    min-height: 407px;
    position: relative;
    width: 100%;
    margin-bottom: 24px;
    border-radius: 16px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.24);

    img {
      object-fit: cover;
      border-radius: 8px;
      width: 100%;
      border-radius: 16px;
      vertical-align: middle;
    }

    .title {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;

      .title_img {
        position: relative;
        margin-left: 12px;
        max-width: 48px;

        img {
          border-radius: 100%;
        }
      }
    }

    .content {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      padding: 16px 24px;
      color: var(--text-white);
      border-radius: 16px;
      background: linear-gradient(0deg,
          rgba(0, 0, 0, 0.4) 0%,
          rgba(0, 0, 0, 0.4) 100%);

      h3 {
        font-size: 1.4em;
        font-style: normal;
        font-weight: 600;
        line-height: 120%;
        /* 37.2px */
        margin: 0;
        margin-bottom: 20px;
      }

      .arrow {
        position: absolute;
        right: 24px;
        bottom: 24px;
        z-index: 5;
        display: flex;
        align-items: center;
        height: 15px;
        width: 50px;

        svg {
          position: absolute;
          right: 0;
          top: 50%;
          transform: translateY(-50%);
        }

        .line {
          position: absolute;
          top: 50%;
          right: 10px;
          height: 2px;
          transform: translateY(-50%);
          width: 50px;
          background-color: var(--text-white);
        }
      }

      span {
        font-size: 31px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%;
        /* 37.2px */
      }
    }
  }

  h2 {
    color: var(--secondary-green);
    text-align: center;
    margin-bottom: 74px;
  }
}

@media (max-width: 768px) {
  .things_wrapper {
    h2 {
      margin-bottom: 38px;
    }

    .event_all {
      margin-top: 12.5px;
    }

    .thing_item {
      min-height: 164px;
      margin-bottom: 11px;

      .content {
        .arrow {
          bottom: 12px;
          right: 12px;
        }

        span.date {
          font-size: 14px;
          display: block;
          margin-top: 24px;
        }

        .title {
          h3 {
            font-size: 18px;
            margin: 0;
          }

          .title_img {
            img {
              border-radius: 100%;
            }
          }
        }
      }
    }
  }
}