.blogSlug {
  padding-top: 13rem;

  a {
    all: unset;
  }

  .leftContent {
    position: sticky;
    top: 20px;
    padding-bottom: 30px;
  }

  .textBottom {
    span {
      margin-top: 20px;
      display: block;
      font-size: 0.9em;
      color: #666;
    }

    p {
      font-size: 0.8em;
      color: #afafaf;
      // margin-bottom: 1.3em;
    }
  }

  .topContent {
    h1 {
      font-size: 4em;
      line-height: 112%;
      letter-spacing: -0.02em;
      text-transform: uppercase;
      font-weight: normal;
      margin: 0;
    }

    p {
      font-size: 1.3em;
      line-height: 120%;
      letter-spacing: -0.02em;
      color: #222;
      margin: 0 0 1em 0;
      display: block;
      margin-top: 30px;
    }
    img {
      max-width: 100%;
    }
  }

  .textContent {
    margin-top: 30px;
    margin-bottom: 30px;
    font-size: 1em;
    line-height: 160%;
    color: #444;
    margin-bottom: 2em;
    max-width: 75%;
    text-align: justify;
  }

  .rightContent {
    border-left: 3px solid #e5e5e5;
    padding-left: 30px;
  }
}
@media screen and (max-width: 768px) {
  .blogSlug {
    padding-top: 5rem;
    .rightContent{
        padding: 0;
        border: 0;
    }
    .topContent {
      h1 {
        font-size: 1.8em;
      }
      p {
        font-size: 1.2em;
      }
      img{
        max-height: 200px;
        object-fit: contain;
      }
    }
    .textContent {
      max-width: 100%;
      img {
        width: 100%;
      }
    }
  }
}
