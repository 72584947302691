.ulus {
    .top_content {
        h2 {
            color: var(--text-black)
        }
    }

    .item {

        position: relative;

        img {
            width: 100%;
            height: auto;
        }

        .overlay {
            position: absolute;
            top: 10px;
            left: 10px;
            width: 100%;
            color: var(--text-white);
            font-size: 22px;
            font-weight: 600;
            line-height: 120%;
        }

        .arrow {
            position: absolute;
            right: 10px;
            bottom: 10px;
            color: var(--text-white);
        }

        .liner {
            position: absolute;
            width: 100%;
            color: var(--text-white);
            height: 100%;
            right: 0px;
            bottom: 0px
        }
    }

}

.right {
    margin-bottom: 110px;
    padding-top: 20px;

    .btn {
        padding: 10px 0px;
        height: auto;
        width: 100%;

    }

    .img_bottom {
        margin-top: 45px;

        img {
            width: 100%;
            height: auto
        }
    }
}