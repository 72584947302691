.search_modal {
  background-color: #000000be;
  position: relative;
  .list_item {
    color: #fff;
    a {
      color: #fff !important;
    }
  }
  .input_search {
    input {
      width: 100%;
      height: 40px;
      border: none;
      border-radius: 5px;
      padding: 0 10px;
      font-size: 16px;
      color: #000;
      background-color: transparent;
      color: #fff;
      border-bottom: 2px solid #fff;
      &::placeholder {
        color: #fff;
      }
      &:focus {
        outline: none;
      }
    }
    button {
      background-color: transparent;
      border: none;
      color: #fff;
      font-size: 20px;
      padding: 10px;
      height: 40px;
      border-bottom: 2px solid #fff;
      cursor: pointer;
      &:focus {
        outline: none;
      }
    }
  }

  .close_icon {
    position: absolute;
    top: 10px;
    right: -50px;
    cursor: pointer;
    color: #fff;
  }
  .ant-modal-content {
    background: transparent !important;
  }
}
