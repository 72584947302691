.hotel_wrapper {
  margin-top: 35px;
  position: relative;
  padding-top: 35px;
  margin-bottom: 40px;

  @media (min-width: 1200px) {
    margin-bottom: 80px;
  }
  .heading_wrapper {
    padding-bottom: 16px;
    border-bottom: 2px solid var(--secondary-green);
    margin-bottom: 48px;
    position: relative;
    z-index: 3;
    h1 {
      font-size: 3em;
      text-align: left;
      margin-bottom: 16px;
    }
    .heading_date {
      color: var(--text-black);
      font-size: 2em;
      font-style: normal;
      font-weight: 600;
    }
  }
  .contact {
    margin-bottom: 40px;
    position: relative;
    z-index: 3;
    p {
      text-align: justify;
      font-size: 24px;
      font-style: normal;
      font-weight: 400;
      line-height: 120%; /* 28.8px */
      margin: 40px 0;
    }
    ul {
      list-style: none;
      padding: 0;
      margin: 0;
      display: flex;
      flex-direction: column;
      width: 100%;
      li {
        display: flex;
        width: 100%;
        font-size: 24px;
        align-items: center;
        &:not(:last-child) {
          margin-bottom: 16px;
        }
        span {
          &.km_pointer {
            border-radius: 24px;
            border: 2px solid var(--008080, #008080);
            background: rgba(0, 128, 128, 0.56);
            width: 16px;
            height: 16px;
          }
          &.text {
            display: block;
            width: 100%;
            margin-left: 16px;
          }
        }
      }
    }
  }
  .map_wrapper {
    margin-bottom: 24px;
    .map_heading {
      h3 {
        margin-top: 48px;
      }
      p {
        margin: 0;
        margin-bottom: 16px;
        display: flex;
        gap: 8px;
        align-items: center;
      }
    }
  }
}
