.event_wrapper {
  position: relative;
  .heading_wrapper {
    padding-top: 10rem;
    position: relative;
    height: 365px;
  }
  h1 {
    color: #fff;
  }
  .calendar_wrapper {
    margin-top: 0;
    padding-top: 42px;
    padding-bottom: 60px;
  }
}

@media (min-width: 1492px) {
  .event_wrapper {
    .calendar_wrapper {
      padding-bottom: 90px;
    }
  }
}
@media (max-width: 1200px) {
  .event_wrapper {
    h1 {
      font-size: 4em;
    }
  }
}
@media (max-width: 1200px) {
  .event_wrapper {
    .heading_wrapper {
      padding: 0;
      height: auto;
      h1 {
        margin: auto;
      }
    }
  }
}
