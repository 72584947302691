.heading_wrapper {
  padding-bottom: 16px;
  margin-bottom: 48px;
  position: relative;
  width: 100%;
  top: 0;
  z-index: 3;
  min-height: 500px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  &::after {
    content: "";
    background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.4) 0%,
      rgba(0, 0, 0, 0.4) 100%
    );
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 3;
  }
  .heading_content {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 70px;
    z-index: 5;
    color: var(--text-white);
    h1 {
      font-size: 4em;
      text-align: left;
      margin-bottom: 16px;
      color: var(--text-white);
      margin: 0;
    }
    span {
      border-radius: 24px;
      border: 1px solid var(--text-white);
      padding: 8px 15px;
    }
  }
  .heading_date {
    color: var(--text-black);
    font-size: 2em;
    font-style: normal;
    font-weight: 600;
  }
}
.contact {
  position: relative;
  z-index: 3;
  margin: 48px 0;
  p {
    text-align: justify;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 28.8px */
    margin: 40px 0;
  }
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    width: 100%;
    li {
      display: flex;
      width: 100%;
      font-size: 24px;
      align-items: center;
      &:not(:last-child) {
        margin-bottom: 16px;
      }
      span {
        &.km_pointer {
          border-radius: 24px;
          border: 2px solid var(--008080, #008080);
          background: rgba(0, 128, 128, 0.56);
          width: 16px;
          height: 16px;
        }
        &.text {
          display: block;
          width: 100%;
          margin-left: 16px;
        }
      }
    }
  }
}
@media (max-width: 768px) {
  .heading_wrapper {
    margin-bottom: 24px;
    .heading_content{
      padding: 25px;
    }
    h1 {
      font-size: 2em;
      margin-bottom: 8px;
    }
  }
  .contact {
    margin: 24px 0;
    p {
      font-size: 1em;
    }
    ul li {
      font-size: 1em;
    }
  }
}
