.btn_wrapper {
  a {
    padding: 12px 20px !important;
    height: auto !important;
    display: flex;
    align-items: center;
  }
  .btn.btn_block {
    width: 100%;
    display: block;
  }

  button.btn {
    margin-bottom: 16px;
    height: 48px !important;
    display: flex;
    align-items: center;
    @media (min-width: 992px) {
      padding-left: 20px !important;
      padding-right: 20px !important;
    }
    border-radius: 8px !important;

    span {
      margin-left: 12px;
      display: block;
    }
  }

  @media (min-width: 1500px) {
    button.btn {
      height: 60px !important;
    }
  }
}
