.register {
    margin: 96px 0;

    .register_item {
        img {
            width: 100%;
            object-fit: contain;
        }
    }

    .bottom_btn {
        text-align: center;
        margin-top: 50px;

        a {
            color: var(--F05C59, #F05C59);
            font-size: 20px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
        }
    }
}