.content_wrapper {
  h3 {
    text-align: left;
    margin: 24px 0;
    font-size: 3em;
  }
  .btn_list {
    margin-top: 38px;
    margin-bottom: 48px;
  }
}
@media (max-width: 768px) {
  .btn_list {
    margin-bottom: 20px;
    gap: 12px !important;
    button {
      margin: 0;
      padding: 20px;
      width: 100%;
      justify-content: center;
    }
  }
}
@media (min-width: 1492px) {
  .content_wrapper {
    h3 {
      margin: 48px 0;
      font-size: 4.2em;
    }
  }
}
