.heading_wrapper {
  padding-bottom: 16px;
  border-bottom: 2px solid var(--secondary-green);
  margin-bottom: 48px;
  position: relative;
  z-index: 3;
  h1 {
    font-size: 4em;
    text-align: left;
    margin-bottom: 16px;
  }
  .heading_date {
    color: var(--text-black);
    font-size: 2em;
    font-style: normal;
    font-weight: 600;
  }
}
.contact {
  position: relative;
  z-index: 3;
  margin: 48px 0;
  p {
    text-align: justify;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 28.8px */
    margin: 40px 0;
  }
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    width: 100%;
    li {
      display: flex;
      width: 100%;
      font-size: 24px;
      align-items: center;
      &:not(:last-child) {
        margin-bottom: 16px;
      }
      span {
        &.km_pointer {
          border-radius: 24px;
          border: 2px solid var(--secondary-green);
          background: #f8c6c5;
          width: 16px;
          height: 16px;
        }
        &.text {
          display: block;
          width: 100%;
          margin-left: 16px;
        }
      }
    }
  }
}
@media (max-width: 768px) {
  .heading_wrapper {
    margin-bottom: 24px;
    h1 {
      font-size: 2em;
      margin-bottom: 8px;
    }
  }
  .contact {
    margin: 24px 0;
    p {
      font-size: 1em;
    }
    ul li {
      font-size: 1em;
    }
  }
}
