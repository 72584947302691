:global(.accessibility-mode) .tour_item {
  border: 4px solid black !important;
  padding: 20px !important;
  background-color: #fff !important;
}


.tour_item {
  color: var(--purple);
  border-radius: 16px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.24);
  background: var(--text-white);
  margin-bottom: 24px;

  .sub_text {
    margin: 24px 0;
  }

  a {
    color: unset;

    &:hover {
      color: unset;
    }
  }

  .tour_image {
    max-width: 100%;
    border-top-right-radius: 16px;
    border-top-left-radius: 16px;
  }

  .tour_content {
    padding: 24px;

    .title {
      width: 100%;
      border-bottom: 1px solid var(--purple);

      span {
        margin: 21px 0px;
        display: block;
        font-size: 24px;
        font-weight: 600;
      }

      .title_img {
        height: 48px;
        width: 48px;
        margin-left: 12px;
        position: relative;

        img {
          object-fit: contain;
          border-radius: 100%;
        }
      }
    }

    .icon_stars {
      margin-top: 24px;

      svg {
        fill: var(--purple);
      }
    }

    .tour_footer {
      margin-top: 30px;

      span {
        font-size: 41px;
        font-weight: 600;
      }
    }
  }
}

@media (max-width: 1640px) {
  .tour_item {
    .tour_image {
      height: 220px;
    }

    .tour_content {
      .title {
        span {
          font-size: 1.2em;
        }
      }

      .tour_footer {
        align-items: center;

        span {
          font-size: 24px;
        }

        svg {
          width: 30px;
          height: 30px;
        }
      }
    }
  }
}

@media (min-width: 768px) and (max-width: 992px) {
  .tour_item {
    margin-bottom: 24px;

    .tour_image {
      height: 260px;
    }
  }
}

@media (max-width: 768px) {
  .tour_item {
    margin-bottom: 12px;

    .tour_content {
      padding: 12px;

      .title {
        padding-bottom: 10px;

        span {
          font-size: 18px;
          margin: 0;
        }

        .title_img {
          display: inline-flex;
          align-items: center;

          img {
            width: 20px;
            height: 20px;
            border-radius: 100%;
            object-fit: cover;
          }
        }
      }

      p.sub_text {
        font-size: 14px;
      }

      .icon_stars {
        svg {
          width: 18px;
        }
      }

      .tour_footer {
        margin-top: 10px;

        span {
          font-size: 14px;
        }

        svg {
          width: 20px;
        }
      }
    }

    .tour_image {
      height: 120px;
    }
  }
}