.restaurant_detail_wrapper {
  margin-bottom: 40px;
  .sub_content_wrapper {
    margin: 24px 0;
    color: var(--text-black);
  }
  .content_head {
    font-size: 30px;
    font-weight: 600;
    margin-bottom: 30px;
    display: block;
  }
  .map_wrapper {
    .addres {
      margin: 38px 0;
    }
  }
}

@media (min-width: 1492px) {
  .restaurant_detail_wrapper {
    .sub_content_wrapper {
      margin: 48px 0;
    }
    .content_head {
      color: var(--text-black);
      font-size: 41px;
      margin-bottom: 48px;
    }
    .map_wrapper {
      .addres {
        margin: 48px 0;
      }
    }
  }
}
