.mobile_header {
  position: relative;
  z-index: 100;
  padding: 20px 0;
  overflow: visible;
  font-size: 14px;
  color: var(--text-purple);

  .select_purple {
    span {
      color: var(--purple);
    }
  }
  .select_white {
    span {
      color: var(--text-white);
    }
  }

  &.fixed {
    .navSelect {
      span {
        color: #fff !important;
      }
    }
  }

  .logo {
    img {
      width: 24px;
      height: 42px;
    }
  }
  nav {
    ul {
      display: flex;
      list-style: none;
      padding: 0;
      margin: 0;
      gap: 16px;
      align-items: center;
      li {
        font-size: 14px;

        &.search_li {
          margin-right: 40px;
        }
        &.burger_li {
          cursor: pointer;
        }
        a {
          display: flex;
          align-items: center;
          font-size: 14px;
        }
        svg {
        }
      }
    }
  }
  &.nav_open {
    background-color: #fff;
    color: var(--purple);
    nav ul li a {
      color: var(--purple);
    }
    .navSelect {
      span {
        color: var(--purple) !important;
      }
    }
  }
  .navSelect {
    span {
      color: var(--text-white) !important;
    }
  }

  .mobile_menu {
    position: fixed;
    background-color: var(--text-white);
    height: 100%;
    width: 100%;
    top: 85px;
    left: 0;
    z-index: 3;
    .nav {
      padding: 19px;
      ul {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        > li {
          font-size: 18px;
          font-style: normal;
          font-weight: 600;
          line-height: 120%; /* 21.6px */
          color: var(--text-black);
          margin-bottom: 18px;
          a {
            text-decoration: none;
            color: var(--text-black);
          }
          .sub_nav {
            li {
              font-weight: 400;
              margin-bottom: 16px;
            }
          }
        }
      }
    }
  }
}
