.enter_wrapper {
  position: relative;
  .heading_wrapper {
    padding-top: 10rem;
    position: relative;
    height: 365px;
  }
  .map_wrapper {
    margin: 0;
    padding-top: 15px;
    padding-bottom: 60px;
    h1 {
      margin-bottom: 10px;
    }
  }
  h1 {
    color: #fff;
  }
}

@media (max-width: 768px) {
  .enter_wrapper {
    margin-bottom: 30px;
    .heading_wrapper {
      padding: 0;
      height: auto;
      h1 {
        margin: 0;
      }
    }
  }
}
