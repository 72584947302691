.room_wrapper {
  .room_heading {
    color: var(--text-black);
    font-style: normal;
    font-weight: 600;
    line-height: 120%; /* 49.2px */
    font-size: 1.5em;
    display: block;
    margin: 48px 0;
  }
  .room_title {
    font-size: 1.4em;
    font-style: normal;
    font-weight: 600;
    line-height: 120%; /* 37.2px */
    margin: 0;
    margin-top: 24px;
  }
  .room_list {
    display: grid;
    column-count: 2;
    column-width: 50%;
    grid-template-columns: auto auto;
    column-gap: 24px;
  }
  .room_item {
    margin-bottom: 48px;
    .room_img {
      img {
        width: 100%;
        height: auto;
        object-fit: cover;
      }
    }
  }
}
@media (max-width: 768px) {
  .room_wrapper {
    .room_heading {
      font-size: 1.3em;
      margin: 24px 0;
    }
    .room_title {
      margin-top: 16px;
      font-size: 22px;
    }
    .room_list {
      column-count: 1;
    }
  }
}
