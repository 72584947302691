:global(.accessibility-mode) .enter_wrapper {
  display: none;
}

.enter_wrapper {
  position: relative;

  .heading_wrapper {
    padding-top: 10rem;
    position: relative;
    height: 365px;
  }

  h1 {
    color: #fff;
  }

  .map_wrapper {
    margin: 0;
    padding-bottom: 90px;
  }
}

@media (min-width: 1492px) {
  .enter_wrapper {
    .map_wrapper {
      padding-bottom: 90px;
    }
  }
}

@media (max-width: 768px) {
  .enter_wrapper {
    .heading_wrapper {
      padding-top: 0;
      height: auto;
    }

    .map_wrapper {
      margin-top: 12px;
    }

    h1 {
      font-size: 54px;
    }

    .map_wrapper {
      padding-top: 12px;
    }
  }
}