.item {
  background-color: var(--text-white);
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
  margin-bottom: 24px;
  a {
    color: unset;
  }
  .item_img {
    width: 100%;
    height: auto;
    vertical-align: middle;
  }
  .item_content {
    padding: 16px 24px;
    .title_wrapper {
      border-bottom: 1px solid var(--text-black);
      padding-bottom: 10px;
      margin-bottom: 18px;
      .title {
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 120%; /* 21.6px */
        margin-bottom: 8px;
        display: block;
      }
      p {
        color: var(--gray);
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%; /* 16.8px */
        margin: 0;
      }
      img {
        border-radius: 100%;
      }
    }
    .content_footer {
      .date {
        font-size: 24px;
        font-weight: 400;
        line-height: 120%; /* 28.8px */
      }
      .address {
        color: var(--gray);
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        margin-top: 4px;
      }
    }
  }
}

@media (min-width: 1200px) {
  .item {
    margin-bottom: 48px;
  }
}
@media (max-width: 992px) {
  .item {
    margin-top: 24px;
  }
}
