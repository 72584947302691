.select_wrapper {
  &.select_bordered {
    border-bottom: 1px solid var(--gray);
    width: 70%;
  }

}

@media (min-width: 1200px) {
  .select_wrapper.select_bordered {
    max-width: 360px;
  }
}

@media (max-width: 768px) {
.select_wrapper {
  height: 27px !important;
}
}