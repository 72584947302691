.attraction_detail_wrapper {
  position: relative;
  padding-top: 60px;
  margin-top: 6rem;
  margin-bottom: 60px;
  .content {
    margin-bottom: 24px;
  }
  .text {
    margin-top: 48px;
  }
  .btn_list {
    margin-top: 48px;
    button {
      height: 48px;
      min-width: 140px;
    }
  }
  .map_wrapper {
    margin: 48px 0;
  }
  .recommended_tours {
    h3 {
      font-size: 3em;
      text-align: left;
    }
  }
}
@media (max-width: 768px) {
  .attraction_detail_wrapper {
    padding-top: 20px;
    .btn_list {
      width: 100%;
      gap: 12px !important;
      button {
        width: 70%;
        margin-bottom: 0;
        min-width: 200px;
      }
    }
    .map_wrapper {
      margin: 24px 0;
    }
  }
}
