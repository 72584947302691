.gallery_wrapper {
  .gallery_left {
    position: relative;
    width: 100%;
    img {
      width: 100%;
      border-radius: 12px;
      height: auto;
      max-height: 500px;
      object-fit: contain;
      vertical-align: middle;
    }
  }
  .gallery_right {
    padding: 0 12px;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(15rem, 1fr));
    grid-gap: 8px;
    align-items: flex-start;
    grid-auto-flow: row dense;

    .gallery_item {
      position: relative;
      margin-bottom: 4px;
      max-width: 300px;
      min-height: 200px;
      width: 100%;
      border-radius: 12px;
      overflow: hidden;
      cursor: pointer;
      .overlay_text {
        cursor: pointer;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        border-radius: 12px;
        background: linear-gradient(
          0deg,
          rgba(0, 0, 0, 0.4) 0%,
          rgba(0, 0, 0, 0.4) 100%
        );

        span {
          color: var(--text-white);
          display: block;
          /* Open Sans/Regular/70px */
          font-size: 3em;
          font-weight: 400;
          line-height: 120%; /* 84px */
        }
      }
      img {
        max-width: 100%;
        height: 100%;
        object-fit: contain;
        border-radius: 12px;
        vertical-align: middle;
      }
    }
  }
}

@media (max-width: 576px) {
  .gallery_wrapper {
    .gallery_left {
      height: 128px;
    }
    .gallery_right {
      padding: 0 6px;
      width: 100%;
      gap: 6px;
      grid-template-columns: repeat(auto-fit, minmax(40%, 1fr));
      .gallery_item {
        margin-bottom: 6px;
        min-height: auto;
        
        .overlay_text {
          span {
            font-size: 1em;
          }
        }
      }
    }
  }
}
