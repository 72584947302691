.program_item {
  border-radius: 12px;
  background: var(--text-white);
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
  padding: 20px;
  margin-bottom: 24px;
  .program_num {
    font-size: 2.5em;
    color: var(--secondary-green);
    font-weight: 400;
  }
  ul {
    color: var(--gray);
    font-size: 1.1em;
    font-weight: 400;
    line-height: normal;
    padding-left: 16px;
  }
}

@media (min-width: 1492px) {
  .program_item {
    padding: 40px;
    margin-bottom: 48px;
  }
}
@media (max-width: 768px) {
  .program_item {
    margin-bottom: 12px;
  }
}
