.tab_wrapper {
  margin: 50px 0;
  p{
    margin-top: 0;
  }
  img {
    border-radius: 100%;
  }
  .btn_list {
    margin-top: 20px;
    button {
      border-radius: 16px !important;
      border: none !important;
    }
  }
}
