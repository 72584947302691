header.header {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 99;
    color: var(--purple);
    transition: 0.3s ease-in-out;
    &.fixed {
        position: fixed;
        top: 0;
        color: var(--text-white);
        nav ul li a {
            color: var(--text-white);
        }
    }
    &.fixed_black {
        top: 0;
        position: fixed;
        color: var(--purple);
        nav ul li a {
            color: var(--purple);
        }
    }
    &.fixed_hotel {
        color: var(--purple);
        span {
            color: var(--purple);
        }
    }
    &.black {
        color: var(--purple);
        nav ul li a {
            color: var(--purple);
        }
    }
    &.headerScroll {
        position: absolute !important;
    }
}
