:global(.accessibility-mode) .aboutWrapper>div>div {
  flex: auto;
  max-width: 100%;

  .aboutRight {
    display: none !important;
  }

}

:global(.accessibility-mode) .aboutRight {
  display: none !important;
}


.aboutWrapper {
  .left {
    padding: 5rem;

    h2 {
      color: var(--secondary-green);
      text-align: left !important;
    }

    p {
      color: var(--gray);
      text-align: justify;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 120%;
      /* 21.6px */
      margin-bottom: 36px;
    }

    .link {
      display: flex;
      align-items: center;
      color: var(--gray);
      font-size: 18px;

      svg {
        margin-left: 10px;
      }
    }
  }

  .aboutRight {
    width: 100%;
    background: url("/images/about-img.webp") no-repeat left center;
    background-size: cover;
    height: 494px;
    padding: 0 !important;
    margin-right: -12px;
  }
}

@media (max-width: 768px) {
  .aboutWrapper {
    .left {
      padding: 47px 22px 22px;

      p {
        font-size: 14px;
      }

      .link {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap: 18px;
        color: var(--gray) !important;
        font-size: 14px;

        svg {
          width: 18px;
        }
      }
    }

    .aboutRight {
      margin-right: 0 !important;
      padding: 12px;
      order: -1;
      height: 246px;
    }
  }
}