.feedback_item {
  padding: 24px 32px;
  border-radius: 16px;
  background: #fff;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.22);
  margin-bottom: 48px;
  img {
    object-fit: cover;
    border-radius: 100%;
    vertical-align: middle;
    object-position: top;
  }
  .title {
    margin-bottom: 24px;
    span {
      font-size: 24px;
      font-weight: 600;
    }
  }
  p {
    font-size: 22px;
    font-weight: 400;
    font-style: normal;
    line-height: 120%; /* 28.8px */
    margin: 0px;
    margin-bottom: 3px;
  }
}
@media (min-width: 1492px) {
  .feedback_item {
    .title {
      span {
        font-size: 41px;
      }
    }
  }
}

@media (max-width: 768px) {
  .feedback_item {
    .title {
      gap: 12px !important;
      span {
        font-size: 14px;
      }
      img {
        width: 33px;
        height: 33px;
      }
    }
    p {
      font-size: 12px;
    }
  }
}
