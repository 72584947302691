.blog_item {
  border-radius: 16px;
  background: #fff;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.22);
  margin-bottom: 16px;
  padding: 24px;
  a,
  a:hover {
    color: unset;
  }
  img {
    width: 100%;
    object-fit: cover;
    border-radius: 16px;
  }
  .blog_content {
    p {
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
      width: 80%;
      margin: 0;
    }
  }
  .arrow {
    position: absolute;
    right: 24px;
    bottom: 24px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 5;
    display: flex;
    align-items: center;
    .line {
      position: absolute;
      top: 50%;
      right: 10px;
      height: 2px;
      transform: translateY(-50%);
      width: 50px;
      background-color: var(--text-black);
    }
  }
}

@media (max-width: 768px) {
  .blog_item {
    padding: 4px;
    margin: 6px 0;
    img {
      height: 75px;
      vertical-align: middle;
    }
    .blog_content {
      p {
        font-size: 14px;
      }
    }
    .arrow {
      right: 0px;
      top: 50%;
      transform: translateY(-50%);
      .line {
        width: 30px;
      }
    }
  }
}
