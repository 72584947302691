.tour_programs {
  margin: 30px 0;
  h3 {
    text-align: left;
    font-size: 4em;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}

@media (min-width: 1200px) {
  .tour_programs {
    margin: 60px 0;
    h3 {
      margin-bottom: 30px;
    }
  }
}
