@media (max-width: 768px) {
  .header_wrapper {
    height: 160px;
    width: 100%;
    position: relative;
    top: 0;
    left: 0;
    z-index: 1;
    padding-top: 65px;

    h1 {
      color: var(--text-white) !important;
    }
  }
}
