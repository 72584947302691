.heading {
  font-size: 121px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 145.2px */
  margin: 0;
  margin-bottom: 19px;
  padding: 0;
  text-align: center;
  color: var(--secondary-green);
  position: relative;
  z-index: 4;
}
@media (max-width: 768px) {
  .heading {
    font-size: 54px;
  }
}
