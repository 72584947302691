.tour_detail_wrapper {
  position: relative;
  .btn_list {
    button {
      height: 48px;
    }
  }

  .map_wrapper {
    margin: 48px 0;
  }
  .recommended_tours {
    h3 {
      font-size: 3em;
      text-align: left;
    }
  }
}
@media (max-width: 768px) {
  .tour_detail_wrapper {
    .btn_list {
      width: 100%;
      gap: 12px !important;
      button {
        width: 100%;
        margin-bottom: 0;
      }
    }
    .map_wrapper {
      margin: 24px 0;
    }

  }
}
