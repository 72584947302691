.feedback_wrapper {
  position: relative;
  padding-top: 120px;
  h2 {
    margin-bottom: 72px;
  }
}

@media (max-width: 768px) {
  .feedback_wrapper {
    padding-top: 20px;
  }
}
