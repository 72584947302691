:global(.accessibility-mode) .welcomeWrapper {
    height: 60vh !important;

    &::after {
        background: #ffffff !important;
    }
}

.welcomeWrapper {
    height: 100vh;
    width: 100%;
    background-color: #3d3a3a;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;

    &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(0deg,
                rgba(0, 0, 0, 0.4) 0%,
                rgba(0, 0, 0, 0.4) 100%);
    }

    .image {
        position: relative;
        z-index: 3;
    }

    .video {
        object-fit: cover;
        display: block;
        width: 100%;
        height: 100vh;
        min-height: 600px;
        position: absolute;
        top: 0;
        left: 0;
    }

    .content {
        position: absolute;
        left: 75px;
        padding: 20px;
        width: 40%;
        bottom: 100px;
        color: #fff;
        z-index: 4;

        p {
            font-size: 31px;
            margin-bottom: 3px;
            font-weight: 600;
        }

        span {
            font-size: 24px;
            font-weight: 400;
            line-height: 120%;
            /* 28.8px */
        }
    }

    .arrowDown {
        position: absolute;
        bottom: 20px;
        left: 50%;
        transform: translateX(-50%);
        animation: arrowAni 2s linear infinite;
    }
}

@keyframes arrowAni {
    0% {
        transform: translateY(-20px) translateX(-50%);
    }

    50% {
        transform: translateY(0px) translateX(-50%);
    }

    100% {
        transform: translateY(-20px) translateX(-50%);
    }
}

@media (max-width: 768px) {
    .welcomeWrapper {
        .content {
            width: 100%;
            position: static;

            span {
                font-size: 14px;
            }
        }

        .image {
            img {
                width: 118px;
                height: 86px;
            }
        }
    }
}