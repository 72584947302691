.desktop_header_wrapper {
  padding: 26px 0;
  .navSelect{
    span{
      color: unset;
    }
  }
  nav {
    ul {
      display: flex;
      list-style: none;
      li {
        &.icon {
          display: inline-flex;
          align-items: center;
        }
        a {
          display: flex;
          align-items: center;
          padding: 12px 25px;
          font-size: 18px;
          font-weight: 600;
          color: var(--purple);
        }
      }
    }
  }
}

@media (max-width:1200px) {
  .desktop_header_wrapper{
    display: none !important;
  } 
}