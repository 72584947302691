.price_include {
  margin: 90px 0;
  position: relative;
  button {
    display: flex;
    align-items: center;
    border: unset;
    border-bottom: 1px solid var(--text-black);
    border-radius: 0;
    height: 48px;
    padding: 3px;
  }
  .price_content {
    padding: 48px 24px;
    border-radius: 24px;
    border: 1px solid var(--red);
    margin-top: 15px;
    top: 100%;
    background-color: #fff;
    z-index: 6;
    span.price_title {
      font-size: 1.2em;
      font-style: normal;
      font-weight: 600;
      line-height: 120%; /* 37.2px */
      display: block;
      margin-bottom: 24px;
    }
    ul {
      margin: 0;
      padding-left: 14px;
      li {
        margin-bottom: 24px;
      }
    }
  }
}

@media (max-width: 768px) {
  .price_include {
    margin: 40px 0;
    .price_content {
      padding: 30px;
      padding-bottom: 0;
      span{
        margin-bottom: 16px;
      }
      .price_item {
        margin-bottom: 30px;
        ul {
          li {
            margin-bottom: 12px;
          }
        }
      }
    }
  }
}
