.event_all {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 30px;
  a {
    display: inline-flex;
    color: var(--gray) !important;
    text-decoration: none;
  }
}
@media (max-width: 768px) {
  .event_all {
    margin-top: 12.5px;
  }
}
