.blog_wrapper {
  position: relative;
  padding-top: 180px;

  .about_heading {
    position: relative;

    h1 {
      margin-bottom: 72px;
    }
  }

  .btn_bottom {
    text-align: center;
    margin: 48px 0;
    font-size: 24px;
    font-weight: 400;
  }
}
@media (max-width: 768px) {
  .blog_wrapper {
    padding-top: 0;
    .blog_list {
      margin-top: 6px;
    }
    .btn_bottom{
        margin-top: 12px;
    }
  }
}
