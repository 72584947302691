.enter_wrapper {
  position: relative;
  margin-bottom: 60px;

  .heading_wrapper {
    padding-top: 10rem;
    position: relative;
    height: 365px;

    .head_border {
      position: relative;
    }
  }

  h1 {
    color: #fff;
    position: relative;
    padding-top: 20px;

    &::after {
      content: "";
      border-top: 1px solid var(--text-white);
      position: absolute;
      width: 100%;
      left: 0;
      top: 0;
    }
  }

  .filter_area {
    padding: 48px 0;
  }
}
@media (max-width: 768px) {
  .enter_wrapper {
    .heading_wrapper {
      padding-top: 0;
      height: auto;
    }
    h1 {
      font-size: 54px;
      margin: 0;

      &::after {
        display: none;
      }
    }
    .filter_area {
      gap: 18px !important;
      .select_input {
        width: calc(50% - 18px);
      }
    }
  }
}
