:global(.accessibility-mode) .item {
  border: 4px solid black !important;
  padding: 20px !important;
  background-color: #fff !important;
}

.whatWrapper {
  position: relative;

  h2 {
    text-align: center;
    color: var(--text-white);
    margin: 60px 0;
    padding-top: 60px;
    position: relative;
  }

  .item {
    position: relative;
    height: 400px;

    @media (min-width: 992px) {
      height: 480px;
    }

    @media (min-width: 1500px) {
      height: 560px;
    }
  }

  .rating {
    color: var(--text-white);
    position: absolute;
    bottom: 48px;
    left: 24px;
    font-size: 41px;

    span {
      margin-right: 8px;
    }
  }
}

@media (max-width: 768px) {
  .whatWrapper {
    h2 {
      margin: 70px 0 43px;
    }

    .item {
      height: 164px;
      margin-bottom: 11px;

      .rating {
        font-size: 14px;
        bottom: 12px;
        left: 12px;

        svg {
          width: 14px;
        }
      }
    }
  }
}