.tour_wrapper {
  position: relative;
  padding: 70px 0;

  h2 {
    text-align: center;
    position: relative;
    color: var(--text-white);
    margin-bottom: 72px;
  }
}

@media screen {
  .tour_wrapper {
    padding: 35px 0;

    h2 {
      margin-bottom: 66px;
    }
  }
}