:global(.accessibility-mode) .footer_item {
  flex: auto;
  max-width: 100%;
}

.footer {
  background-color: #323232;
  padding: 73px 96px 10px 67px;
  color: #fff;

  .links {
    font-size: 18px;
    font-style: normal;
    font-weight: 400;

    ul {
      padding: 0;
      margin: 0;
      list-style: none;

      a {
        color: unset;
        margin-bottom: 10px;
        display: block;
      }
    }
  }

  .footer_item,
  .footer_contact {
    span {
      font-size: 18px;
      font-weight: 600;
      margin-bottom: 18px;
      display: block;
    }
  }

  .footer_contact {
    a {
      color: unset;
      display: block;
      margin-bottom: 10px;
    }
  }
}

@media (max-width: 768px) {
  .footer {
    padding: 13px 19px;

    .footer_item {
      .footer_flex {
        gap: 24px !important;
      }
    }

    .footer_contact {
      padding-left: 24px;
    }

    img {
      width: 39px;
      height: 68px;
    }

    .logo {
      margin-bottom: 15px;
      text-align: center;
    }

    .links {
      ul {
        a {
          font-size: 12px;
        }
      }
    }

    .footer_contact {

      a,
      p {
        font-size: 12px;
      }
    }
  }
}