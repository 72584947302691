.interactive_wrapper {
  margin-top: 108px;
  padding-top: 100px;
  margin-bottom: 192px;
  position: relative;
  &.mt_0 {
    margin-top: 0;
    margin-bottom: 40px;
  }
  .section_bg {
    background: url(/images/half_hed.png);
    height: 420px;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  }
  h2 {
    color: var(--secondary-green);
    margin-bottom: 72px;
    text-align: center;
  }
  .btn_list {
    margin-top: 120px;
  }
  .result {
    color: var(--gray);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    text-align: center;
    display: block;
  }
  .map_left {
    padding: 33px 22px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
    border-radius: 12px;
    min-height: 543px;
    background-color: var(--text-white);
  }
}

@media (max-width: 768px) {
  .interactive_wrapper {
    margin: 48px 0;
    padding-top: 20px;
    
    .map_left {
      margin-bottom: 48px;
    }
  }
}
